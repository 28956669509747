import React, {useEffect, useState} from 'react';
import ReactMarkdown from 'react-markdown';
import {markContentComplete} from '../api/content';
import {format, isToday} from 'date-fns';
import {StarIcon as StarIconOutline} from '@heroicons/react/24/outline';
import {StarIcon as StarIconFilled} from '@heroicons/react/24/solid';
import {getRating, submitRatingForm} from '../api/survey';
import Button from '../baseComponents/Button';
import rehypeRaw from 'rehype-raw';

export default function ContentItem(props: {
  title: string;
  week: number;
  text?: string;
  videoUrls?: string[];
  id: number;
  userId?: number;
  userEmail?: string;
  disableCompletion?: boolean;
  lastCompleted?: Date;
}) {
  if (!props.disableCompletion && !props.userId) {
    throw new Error('one of disableCompletion or userId must be set.');
  }
  const [selectedRating, setSelectedRating] = useState<number>();
  const [lastCompletedDynamic, setLastCompletedDynamic] = useState<
    Date | undefined
  >(props.lastCompleted);

  const [firstVideoUrl, ...restVideoUrls] = props.videoUrls || [];

  useEffect(() => {
    setLastCompletedDynamic(props.lastCompleted);
  }, [props.lastCompleted]);

  useEffect(() => {
    if (props.userId) {
      getRating(props.userId, props.id).then(setSelectedRating);
    }
  }, [props.id, props.userId]);

  return (
    <div className="p-3 bg-gray-200 rounded-md">
      <div className="text-3xl pb-2 font-title">{props.title}</div>
      {props.text && (
        // @ts-expect-error see: https://github.com/orgs/rehypejs/discussions/63
        <ReactMarkdown rehypePlugins={[rehypeRaw]} children={props.text} />
      )}
      <div className="flex flex-wrap justify-center">
        {firstVideoUrl ? (
          <iframe
            key={firstVideoUrl}
            title="vimeo-player"
            src={firstVideoUrl}
            className="sm:w-[500px] sm:h-[360px] w-4/5 h-[200px] p-1"
            frameBorder="0"
            allowFullScreen
          />
        ) : (
          <></>
        )}
      </div>
      <div className="flex flex-wrap justify-center">
        {restVideoUrls.length > 1 ? (
          restVideoUrls.map(videoUrl => (
            <iframe
              key={videoUrl}
              title="vimeo-player"
              src={videoUrl}
              className="sm:w-[500px] sm:h-[360px] w-4/5 h-[200px] p-1"
              frameBorder="0"
              allowFullScreen
            />
          ))
        ) : (
          <></>
        )}
      </div>
      <div className="flex flex-col items-center space-y-2">
        {!props.disableCompletion && (
          <div className="flex flex-row items-center">
            <div className="text-sm">My rating:</div>
            <RateContent
              selectedRating={selectedRating}
              onRate={async rating => {
                setSelectedRating(rating);
                await submitRatingForm(rating, props.id, props.userId!);
              }}
            />
          </div>
        )}
        {!props.disableCompletion ? (
          lastCompletedDynamic && isToday(lastCompletedDynamic) ? (
            <Button disabled={true}>
              Completed today at {format(lastCompletedDynamic, 'h aaa')}
            </Button>
          ) : (
            <Button
              onClick={async () => {
                setLastCompletedDynamic(new Date());
                return markContentComplete(props.userId!, props.id, new Date());
              }}
            >
              Mark activity completed
            </Button>
          )
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

function RateContent(props: {
  selectedRating: number | undefined;
  onRate: (rating: number) => void;
}) {
  const {selectedRating, onRate} = props;
  const [hoveredIndex, setHoveredIndex] = useState<number | undefined>();

  useEffect(() => {
    setHoveredIndex(selectedRating);
  }, [selectedRating]);

  const stars: JSX.Element[] = [];
  for (let index = 1; index <= 5; index++) {
    const onMouseOver = () => setHoveredIndex(index);
    const onMouseLeave = () => setHoveredIndex(selectedRating);
    const onClick = () => onRate(index);
    const className = 'h-4 w-4 cursor-pointer';
    if (hoveredIndex && index <= hoveredIndex) {
      stars.push(
        <StarIconFilled
          key={index}
          className={className}
          onClick={onClick}
          onMouseOver={onMouseOver}
          onMouseLeave={onMouseLeave}
        />
      );
    } else {
      stars.push(
        <StarIconOutline
          key={index}
          className={className}
          onClick={onClick}
          onMouseOver={onMouseOver}
          onMouseLeave={onMouseLeave}
        />
      );
    }
  }
  return <div className="flex flex-row mx-1">{stars}</div>;
}
