import React, {useState, useEffect} from 'react';
import Button from '../baseComponents/Button';
import Input from '../baseComponents/Input';
import {useFieldArray, useForm} from 'react-hook-form';
import {ErrorMessage} from '@hookform/error-message';
import {getProfile, UserProfile, saveProfile} from '../api/profile';
import {format} from 'date-fns';
import {TrashIcon} from '@heroicons/react/24/outline';
import {useSearchParams, useNavigate} from 'react-router-dom';

export default function Component() {
  const [profile, setProfile] = useState<UserProfile>();
  useEffect(() => {
    getProfile().then(setProfile);
  }, []);
  return profile ? <Profile profile={profile} /> : <></>;
}

function Profile(props: {profile: UserProfile}) {
  const {profile} = props;
  const {
    register,
    handleSubmit,
    formState: {errors},
    control,
  } = useForm({
    defaultValues: {
      password: '',
      email: profile.email,
      displayName: profile.displayName,
      pregnancies: (profile.pregnancies || []).map(pregnancy => ({
        ...pregnancy,
        dueDate: pregnancy.dueDate
          ? format(new Date(pregnancy.dueDate), 'yyyy-MM-dd')
          : '',
        completionDate: pregnancy.completionDate
          ? format(new Date(pregnancy.completionDate), 'yyyy-MM-dd')
          : '',
      })),
    },
  });
  const {fields, append, remove} = useFieldArray({
    control,
    name: 'pregnancies',
  });
  const navigate = useNavigate();
  const searchParams = useSearchParams()[0];
  const profileIsNotComplete = searchParams.get('incompleteProfile') === 'true';

  const [isSaving, setIsSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [success, setSuccess] = useState(false);

  return (
    <div className="flex flex-col items-center">
      <div className="flex flex-col space-y-3 p-5 text-theme-navy w-full sm:w-2/3 md:w-1/2">
        {profileIsNotComplete && (
          <div>
            Welcome! Please complete your profile below by adding at least one
            pregnancy.
          </div>
        )}
        <form
          onSubmit={handleSubmit(async data => {
            setIsSaving(true);
            setErrorMessage('');
            try {
              await saveProfile({
                ...data,
                id: profile.id,
              });
              setSuccess(true);
              if (profileIsNotComplete) {
                navigate('/?profileCompleted=true');
              }
            } catch (e) {
              setErrorMessage((e as Error).message);
            }
            setIsSaving(false);
          })}
          className="flex flex-col space-y-2 rounded bg-gray-100 p-3 w-full"
        >
          <div className="text-xl font-title">User</div>
          {/* email */}
          <div>
            <label htmlFor="login-email" className="text-sm">
              Email
            </label>
            <br />
            <Input
              type="email"
              id="login-email"
              disabled={true}
              defaultValue={profile.email}
            />
          </div>
          {/* display name */}
          <div>
            <label htmlFor="display-name" className="text-sm">
              Display Name
            </label>
            <br />
            <Input type="text" id="display-name" {...register('displayName')} />
          </div>
          {/* pregnancies */}
          <div className="flex flex-col space-y-2 rounded">
            <div className="text-xl pb-1 font-title">Pregnancies</div>
            {fields.map((_field, index) => (
              <Pregnancy key={index} {...{index, register, onDelete: remove}} />
            ))}
            <Button
              onClick={e => {
                append({dueDate: '', completionDate: ''});
                e.preventDefault();
              }}
              variant="secondary"
            >
              {fields.length ? 'Add Another Pregnancy' : 'Add a Pregnancy'}
            </Button>
          </div>
          {/* submit */}
          <Button
            type="submit"
            disabled={isSaving}
            onClick={() => setSuccess(false)}
            className="!mt-8"
          >
            Update Profile
          </Button>
          {/* errors & notices */}
          {success && <div>Profile successfully updated.</div>}
          <div className="text-theme-red text-sm">
            <ErrorMessage errors={errors} name="password" />
            {fields.map((field, index) => (
              <ErrorMessage
                key={index}
                errors={errors}
                name={`pregnancies.${index}.dueDate`}
              />
            ))}
            <div>{errorMessage}</div>
          </div>
        </form>
      </div>
    </div>
  );
}

export function Pregnancy(props: {
  index: number;
  onDelete: (index: number) => void;
  register: any;
}) {
  const {index, onDelete, register} = props;
  return (
    <div className="flex flex-col space-y-2 p-3 border border-1 border-theme-navy ">
      <div className="flex flex-row">
        <div>Pregnancy {index + 1}</div>
        <div className="grow">
          <TrashIcon
            className="cursor-pointer ml-2 h-5 w-5 text-slate-500"
            aria-label="delete"
            onClick={() => onDelete(index)}
          />
        </div>
      </div>
      <div>
        <label htmlFor={`pregnancy-${index}-dueDate`} className="text-sm">
          Due Date
        </label>
        <br />
        <Input
          type="date"
          id={`pregnancy-${index}-dueDate`}
          {...register(`pregnancies.${index}.dueDate`, {
            required: `Due date for pregnancy ${index + 1} is required.`,
          })}
        />
      </div>
      <div>
        <label
          htmlFor={`pregnancy-${index}-completion-date`}
          className="text-sm"
        >
          Pregnancy end date (if applicable)
        </label>
        <br />
        <Input
          type="date"
          id={`pregnancy-${index}-completion-date`}
          {...register(`pregnancies.${index}.completionDate`)}
        />
      </div>
    </div>
  );
}
