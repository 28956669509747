import React, {useEffect, useState} from 'react';
import {Content, getCompletions, getContent} from '../api/content';
import ContentItem from './ContentItem';
import {getProfile, UserProfile} from '../api/profile';
import {Navigate, useSearchParams} from 'react-router-dom';
import {
  getSurveysByCardinality,
  markSurveyComplete,
  Survey,
} from '../api/survey';
import Button from '../baseComponents/Button';
import {format, startOfWeek} from 'date-fns';

export default function Component() {
  const [profile, setProfile] = useState<UserProfile>();
  useEffect(() => {
    getProfile().then(setProfile);
  }, []);
  return profile ? <ContentFeed profile={profile} /> : <></>;
}

export function ContentFeed(props: {profile: UserProfile}) {
  const {profile} = props;
  const searchParams = useSearchParams()[0];
  const profileIsComplete = profile.pregnancies?.length;
  const profileWasJustCompleted = searchParams.get('profileCompleted');
  const [content, setContent] = useState<Content[]>([]);
  const [surveys, setSurveys] = useState<Survey[]>();
  const [numberCompletions, setNumberCompletions] = useState<number>(0);

  useEffect(() => {
    if (profileIsComplete) {
      getContent(profile.id).then(content => {
        setContent(content || []);
        if (content[0]) {
          getSurveysByCardinality(profile.id, content[0].cardinality).then(
            setSurveys
          );
        } else {
          setSurveys([]);
        }
      });
      getCompletions(startOfWeek(new Date()), profile.id).then(contentList => {
        let completions = 0;
        contentList.forEach(
          content => (completions += content.contentCompletions?.length || 0)
        );
        setNumberCompletions(completions);
      });
    }
  }, [profileIsComplete, profile.id]);
  if (profileIsComplete) {
    if (!surveys || !content) {
      return <>Loading...</>;
    } else {
      const surveysToComplete = surveys.filter(
        ({surveyCompletions}) => !surveyCompletions?.length
      );
      if (surveysToComplete.length) {
        return (
          <>
            {surveysToComplete.map(survey => (
              <div className="flex flex-col items-center space-y-2">
                <iframe
                  src={survey.url}
                  title={survey.url}
                  className="h-[400px] w-full"
                />
                <Button
                  onClick={async () => {
                    await markSurveyComplete(profile.id, survey.id);
                    setSurveys(surveys.filter(({id}) => id !== survey.id));
                  }}
                >
                  Mark Survey Completed
                </Button>
              </div>
            ))}
          </>
        );
      } else {
        return (
          <>
            {profileWasJustCompleted && (
              <div>
                Welcome to core2u! Now that you've completed your profile, dive
                in to our content.
              </div>
            )}
            <div className="bg-purple-100 border-l-4 border-theme-purple p-2">
              Happy {format(new Date(), 'EEEE')}!{' '}
              {determineDailyCopy(
                format(new Date(), 'EEEE'),
                numberCompletions || 0
              )}
            </div>
            {content.map(contentItem => (
              <ContentItem
                key={contentItem.id}
                week={contentItem.cardinality + 1}
                id={contentItem.id}
                userId={profile.id}
                userEmail={profile.email}
                title={contentItem.title}
                text={contentItem.text}
                videoUrls={contentItem.videoUrls || []}
                lastCompleted={
                  contentItem.contentCompletions?.sort(
                    (a, b) =>
                      b.completionDate.getTime() - a.completionDate.getTime()
                  )[0]?.completionDate
                }
              />
            ))}
          </>
        );
      }
    }
  } else {
    return <Navigate to="/user?incompleteProfile=true" replace />;
  }
}

function determineDailyCopy(day: string, completions: number): string {
  if (!completions) {
    return 'Take the time for yourself and check out what we have in store for you this week.';
  } else {
    return `Keep up the great work! You have completed ${completions} exercise${
      completions !== 1 ? 's' : ''
    } this week.`;
  }
}
