import decode from 'jwt-decode';

export function setToken(token: string): void {
  localStorage.setItem('token', token);
}

export function getToken(): string | null {
  const token = localStorage.getItem('token');
  if (!token) {
    return null;
  } else {
    const expirationDate = (decode(token) as {exp: number}).exp * 1000;
    // don't return the token if it will expire in the next minute.
    if (
      new Date(expirationDate).getTime() <=
      new Date().getTime() + 60 * 1000
    ) {
      clearToken();
      window.location.reload();
      return null;
    } else {
      return token;
    }
  }
}

export function clearToken() {
  localStorage.removeItem('token');
}

export function getDecodedToken(): {sub: string; exp: number} | null {
  const token = getToken();
  if (!token) {
    return null;
  } else {
    return decode(token) as {sub: string; exp: number};
  }
}
