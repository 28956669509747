import React, {useContext, useEffect} from 'react';
import Button from '../baseComponents/Button';
import {Navigate, useLocation} from 'react-router-dom';
import {setToken} from './token';
import {SetTokenContext} from './TokenContext';
import decode from 'jwt-decode';

export default function Login() {
  const {search} = useLocation();
  let alert = '';
  if (new URLSearchParams(search).get('invalid-login')) {
    alert =
      'We were unable to log you in. Do you have an email associated with the provider you tried to log in with?';
  }
  return (
    <div className="flex flex-col space-y-2 rounded bg-gray-100 p-3 w-full sm:w-2/3 md:w-1/2 items-center">
      <div className="text-2xl font-semibold font-title">Welcome!</div>
      <div className="text-lg">Log in or create an account:</div>
      {alert && <div className="bg-yellow-100 p-2">{alert}</div>}
      <div className="flex flex-row space-x-[20px]">
        <div>
          <a href={`${process.env.REACT_APP_API_BASE_URL}/auth/login/google`}>
            <Button>
              <div className="flex flex-row p-1 items-center space-x-2">
                <img
                  className="h-5 w-5"
                  alt="Google sign-in"
                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Google_%22G%22_Logo.svg/512px-Google_%22G%22_Logo.svg.png"
                />
                <div>Google</div>
              </div>
            </Button>
          </a>
        </div>
        <div>
          <a href={`${process.env.REACT_APP_API_BASE_URL}/auth/login/facebook`}>
            <Button>
              <div className="flex flex-row p-1 items-center space-x-2">
                <img
                  className="h-5 w-5"
                  alt="Google sign-in"
                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/51/Facebook_f_logo_%282019%29.svg/240px-Facebook_f_logo_%282019%29.svg.png"
                />
                <div>Facebook</div>
              </div>
            </Button>
          </a>
        </div>
      </div>
      <div className="text-sm pt-2">
        By creating an account, you agree to our{' '}
        <a
          href="https://mycore2u.com/privacy-policy"
          rel="noreferrer"
          target="_blank"
          className="underline"
        >
          Privacy Policy
        </a>{' '}
        and{' '}
        <a
          href="https://mycore2u.com/terms-of-use"
          rel="noreferrer"
          target="_blank"
          className="underline"
        >
          Terms of Use
        </a>
        .
      </div>
    </div>
  );
}

export function LoginRedirect() {
  const {search} = useLocation();
  const setTokenContext = useContext(SetTokenContext);
  useEffect(() => {
    const token = new URLSearchParams(search).get('token');
    if (token) {
      setToken(token);
      if (setTokenContext) {
        setTokenContext(decode(token));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <Navigate to="/" replace />;
}
