import React from 'react';

export default function Button(
  props: React.ButtonHTMLAttributes<HTMLButtonElement> & {
    variant?: 'primary' | 'secondary';
  }
) {
  const {className, variant, ...otherProps} = props;
  let colorClasses: string;
  if (variant === 'secondary') {
    colorClasses = 'bg-theme-navy hover:bg-[#625593] disabled:bg-purple-100';
  } else {
    colorClasses = 'hover:bg-[#9b5aa3] disabled:bg-purple-100 bg-theme-purple';
  }
  return (
    <button
      className={`rounded-md font-bold font-title text-white disabled:text-gray-400 px-[20px] lg:min-w-[190px] min-w-[130px] lg:min-h-[50px] min-h-[40px] ${colorClasses} ${className}`}
      {...otherProps}
    >
      <div className="flex text-center justify-center">{props.children}</div>
    </button>
  );
}
