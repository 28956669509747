import fetchWrapper from '../utils/fetch';
import {Content} from './content';
import {UserProfile} from './profile';

export type Survey = {
  id: number;
  url: string;
  cardinalities: number[];
  updatedAt?: Date;
  updatedBy?: string;
  createdAt?: Date;
  createdBy?: string;
  surveyCompletions?: SurveyCompletion[];
};

export type SurveyCompletion = {
  id: number;
  updatedAt?: Date;
  updatedBy?: string;
  createdAt?: Date;
  createdBy?: string;
  survey?: Survey;
  user?: UserProfile;
  completionDate: Date;
};

type ContentRating = {
  id: number;
  updatedAt?: Date;
  updatedBy?: string;
  createdAt?: Date;
  createdBy: string;
  content: Content;
  user: UserProfile;
  rating: number;
};

export async function getSurveysByCardinality(
  userId: number,
  cardinality: number
) {
  const response = await fetchWrapper(
    `${process.env.REACT_APP_API_BASE_URL}/user/${userId}/survey?cardinality=${cardinality}`
  );
  if (response.status === 204) {
    return [];
  }
  const rawResults = await response.json();
  const formattedResults = rawResults.map(
    (survey: any): Survey => ({
      ...survey,
      createdAt: new Date(survey.createdAt),
      updatedAt: new Date(survey.updatedAt),
      surveyCompletions: survey.surveyCompletions.map(
        (rawCompletion: any): SurveyCompletion => ({
          ...rawCompletion,
          completionDate: new Date(rawCompletion.completionDate),
          createdAt: new Date(rawCompletion.createdAt),
          updatedAt: new Date(rawCompletion.updatedAt),
        })
      ),
    })
  );
  return formattedResults;
}

export async function markSurveyComplete(userId: number, surveyId: number) {
  const response = await fetchWrapper(
    `${process.env.REACT_APP_API_BASE_URL}/user/${userId}/survey/${surveyId}/completion`,
    {
      method: 'POST',
      body: JSON.stringify({completionDate: new Date()}),
      headers: {'Content-Type': 'application/json'},
    }
  );
  return response.json() as Promise<SurveyCompletion>;
}

export async function getSurveys() {
  const response = await fetchWrapper(
    `${process.env.REACT_APP_API_BASE_URL}/survey`
  );
  const rawResults = await response.json();
  const formattedResults = rawResults.map((survey: any) => ({
    ...survey,
    createdAt: new Date(survey.createdAt),
    updatedAt: new Date(survey.updatedAt),
  }));
  return formattedResults as Survey[];
}

export async function createSurvey(survey: Survey) {
  const response = await fetchWrapper(
    `${process.env.REACT_APP_API_BASE_URL}/survey`,
    {
      method: 'POST',
      body: JSON.stringify(survey),
      headers: {'Content-Type': 'application/json'},
    }
  );
  return response.json() as Promise<Survey>;
}

export async function deleteSurvey(survey: Survey) {
  if (!survey.id) {
    throw new Error('cannot delete survey without an ID');
  }
  const response = await fetchWrapper(
    `${process.env.REACT_APP_API_BASE_URL}/survey/${survey.id}`,
    {
      method: 'DELETE',
    }
  );
  if (!response.ok) {
    throw new Error(
      `Delete survey failed with status ${
        response.status
      }: ${await response.text()}`
    );
  } else return true;
}

export async function submitRatingForm(
  rating: number,
  contentId: number,
  userId: number
) {
  const response = await fetchWrapper(
    `${process.env.REACT_APP_API_BASE_URL}/user/${userId}/content/${contentId}/rating`,
    {
      method: 'POST',
      body: JSON.stringify({rating}),
      headers: {'Content-Type': 'application/json'},
    }
  );
  return response.json() as Promise<ContentRating>;
}

export async function getRating(
  userId: number,
  contentId: number
): Promise<number | undefined> {
  const response = await fetchWrapper(
    `${process.env.REACT_APP_API_BASE_URL}/user/${userId}/content/${contentId}/rating`
  );
  if (response.status === 204) {
    return;
  }
  const res = await response.json();
  return +res.rating;
}
