import fetchWrapper from '../utils/fetch';
import {format} from 'date-fns';

export type Content = {
  id: number;
  cardinality: number;
  title: string;
  text?: string;
  tags?: string[] | null;
  videoUrls?: string[];
  updatedAt?: Date;
  updatedBy?: string;
  createdAt?: Date;
  createdBy?: string;
  contentCompletions?: ContentCompletion[];
};

type ContentCompletion = {
  id: number;
  completionDate: Date;
  createdBy: string;
  createdAt: Date;
  updatedBy?: string;
  updatedAt?: Date;
};

export async function getContent(userId: number): Promise<Content[]> {
  const response = await fetchWrapper(
    `${process.env.REACT_APP_API_BASE_URL}/user/${userId}/content`
  );
  if (response.status === 204) {
    return [];
  }
  const rawResults = await response.json();
  const formattedResults = rawResults.map((content: any) => ({
    ...content,
    createdAt: new Date(content.createdAt),
    updatedAt: new Date(content.updatedAt),
    contentCompletions: content.contentCompletions.map((completion: any) => ({
      ...completion,
      completionDate: new Date(completion.completionDate),
      createdAt: new Date(completion.createdAt),
      updatedAt: new Date(completion.updatedAt),
    })),
  }));
  return formattedResults as Content[];
}

export async function addContent(content: Content) {
  const response = await fetchWrapper(
    `${process.env.REACT_APP_API_BASE_URL}/content`,
    {
      method: 'POST',
      body: JSON.stringify({content}),
      headers: {'Content-Type': 'application/json'},
    }
  );
  return response.json() as Promise<Content>;
}

export async function updateContent(id: number, content: Content) {
  const response = await fetchWrapper(
    `${process.env.REACT_APP_API_BASE_URL}/content/${id}`,
    {
      method: 'PUT',
      body: JSON.stringify({content}),
      headers: {'Content-Type': 'application/json'},
    }
  );
  return response.json() as Promise<Content>;
}

export async function getAllContent() {
  const response = await fetchWrapper(
    `${process.env.REACT_APP_API_BASE_URL}/content/`
  );
  return response.json() as Promise<Content[]>;
}

export async function markContentComplete(
  userId: number,
  contentId: number,
  completionDate: Date
) {
  const response = await fetchWrapper(
    `${process.env.REACT_APP_API_BASE_URL}/user/${userId}/content/${contentId}/completion`,
    {
      method: 'POST',
      body: JSON.stringify({completionDate}),
      headers: {'Content-Type': 'application/json'},
    }
  );
  return response.json() as Promise<Content[]>;
}

export async function getCompletions(fromDate: Date, userId: number) {
  const response = await fetchWrapper(
    `${
      process.env.REACT_APP_API_BASE_URL
    }/user/${userId}/content?fromDate=${format(fromDate, 'yyyyMMdd')}`
  );
  return response.json() as Promise<Content[]>;
}
