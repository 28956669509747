import React, {useState} from 'react';
import fetchWrapper from '../utils/fetch';
import Button from '../baseComponents/Button';

export default function CompletionFetcher() {
  const [fromDate, setFromDate] = useState<string>();
  const [toDate, setToDate] = useState<string>();
  const [disableButton, setDisableButton] = useState(false);
  return (
    <form
      name="completions"
      onSubmit={async e => {
        e.preventDefault();
        setDisableButton(true);
        const filters: {[key: string]: string} = {};
        if (fromDate) {
          filters.fromDate = fromDate.replace('-', '');
        }
        if (toDate) {
          filters.toDate = toDate.replace('-', '');
        }
        const response = await fetchWrapper(
          `${
            process.env.REACT_APP_API_BASE_URL
          }/content/completion?${new URLSearchParams(filters).toString()}`
        );
        const csvBlob = await response.blob();
        const url = window.URL.createObjectURL(csvBlob);
        var downloadLink = document.createElement('a');
        downloadLink.href = url;
        downloadLink.download = 'activity.csv';
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        setDisableButton(false);
      }}
    >
      <div className="text-xl">Get User Activity</div>
      <label>
        From (optional):{' '}
        <input
          className="border border-black mr-2"
          type="date"
          value={fromDate}
          onChange={e => setFromDate(e.target.value)}
        />
      </label>
      <label>
        To (optional):{' '}
        <input
          className="border border-black mr-2"
          type="date"
          value={toDate}
          onChange={e => setToDate(e.target.value)}
        />
      </label>
      <Button type="submit" disabled={disableButton}>
        Download user activity
      </Button>
    </form>
  );
}
