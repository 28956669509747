import {getToken} from '../auth/token';

export default async function fetchWrapper(
  input: RequestInfo | URL,
  init?: RequestInit
): Promise<Response> {
  const token = getToken();
  const newInit = {
    ...init,
    headers: {...init?.headers, Authorization: `Bearer ${token}`},
  };
  const response = await fetch(input, newInit);
  if (response.ok) {
    return response;
  } else {
    let errorMessage = `fetch to ${input} failed with status ${response.status}: `;
    const responseText = await response.text();
    if (responseText) {
      errorMessage += responseText;
    } else {
      const responseJson = await response.json();
      if (responseJson) {
        errorMessage += JSON.stringify(responseJson);
      }
    }
    throw new Error(errorMessage);
  }
}
