import React, {useContext} from 'react';
import Login from './auth/Login';
import {TokenContext} from './auth/TokenContext';
import ContentFeed from './content/ContentFeed';

export default function App() {
  const isLoggedIn = useContext(TokenContext);

  let extraClasses = '';
  if (!isLoggedIn) {
    extraClasses =
      'bg-login bg-cover h-full w-full bg-center shadow-[inset_0_0_0_1000px_rgba(67,44,126,0.59)]';
    // inset 0 0 0 1000px rgb(245 55 55 / 52%)
  }

  return (
    <div className={`bg-white text-theme-navy ${extraClasses}`}>
      <div className="flex flex-col p-5 space-y-5">
        {isLoggedIn ? (
          <ContentFeed />
        ) : (
          <div className="flex flex-col md:flex-row space-y-3 md:space-x-5 md:space-y-0 place-items-center md:place-items-stretch md:justify-center">
            <Login />
          </div>
        )}
      </div>
    </div>
  );
}
