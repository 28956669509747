import React, {useEffect, useState} from 'react';
import {Content, getAllContent} from '../api/content';
import {ChevronRightIcon, ChevronDownIcon} from '@heroicons/react/24/outline';
import ContentItem from './ContentItem';

export default function ContentLibrary() {
  const [allContent, setAllContent] = useState<Content[]>();

  useEffect(() => {
    getAllContent().then(content =>
      setAllContent(
        content.sort((a, b) =>
          a.cardinality === b.cardinality
            ? a.cardinality - b.cardinality
            : a.id - b.id
        )
      )
    );
  }, []);

  return (
    <div className="p-2 space-y-2">
      <div className="text-2xl font-semibold font-title">Content Library</div>
      <div>
        Here you can see all of core2u's content for every week of our program.
        Want to revisit exercises from weeks past, or take a glance at what's
        next? Here's the place to do it.
      </div>
      <div>
        {allContent?.map(content => (
          <IndividualContentDisplay content={content} key={content.id} />
        ))}
      </div>
    </div>
  );
}

function IndividualContentDisplay(props: {content: Content}) {
  const {content} = props;
  const [showDetail, setShowDetail] = useState(false);
  return (
    <div className="flex flex-col space-y-2 rounded p-2 bg-gray-100">
      <div
        className="flex flex-row items-center space-x-1 text-theme-purple cursor-pointer"
        onClick={() => setShowDetail(!showDetail)}
      >
        <div>
          Week {content.cardinality + 1}: {content.title}
        </div>
        {showDetail ? (
          <ChevronDownIcon className="h-4 w-4" />
        ) : (
          <ChevronRightIcon className="h-4 w-4" />
        )}
      </div>
      {showDetail && (
        <ContentItem
          id={content.id}
          week={content.cardinality + 1}
          disableCompletion
          title={content.title}
          text={content.text}
          videoUrls={content.videoUrls}
        />
      )}
    </div>
  );
}
