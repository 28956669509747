/* istanbul ignore file */
// this file should be basically a wrapper around the App
// component, and nothing else.
import React, {useContext, useState} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  Link,
  Navigate,
  Outlet,
  RouterProvider,
  useLocation,
} from 'react-router-dom';
import Profile from './profile/Profile';
import {clearToken, getDecodedToken} from './auth/token';
import {Bars3Icon} from '@heroicons/react/24/outline';
import {SetTokenContext, TokenContext} from './auth/TokenContext';
import Feedback from './baseComponents/Feedback';
import AdminPage from './AdminPage';
import ContentLibrary from './content/ContentLibrary';
import {Menu} from '@headlessui/react';
import {LoginRedirect} from './auth/Login';
import logoAndTitle from './logo_and_title.png';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

function Layout() {
  const [token, setToken] = useState(getDecodedToken());
  return (
    <SetTokenContext.Provider value={setToken}>
      <TokenContext.Provider value={token}>
        <div className="flex flex-col h-full">
          <div className="text-5xl flex flex-row bg-theme-navy p-4">
            <div className="grow-0 h-8 w-8"></div>
            <div className="flex grow">
              <div>
                <Link to="/">
                  <img
                    src={logoAndTitle}
                    alt="core2u"
                    className="w-[120px] md:w-[200px]"
                  />
                </Link>
              </div>
            </div>
            <div className="grow-0 h-8 md:h-12 w-16 md:w-24 text-white">
              <div className="flex flex-row">
                <DropdownMenu />
                <Feedback />
              </div>
            </div>
          </div>
          <div className="flex-grow h-full overflow-y-auto">
            <Outlet />
          </div>
        </div>
      </TokenContext.Provider>
    </SetTokenContext.Provider>
  );
}

function DropdownMenu() {
  const token = useContext(TokenContext);
  const menuItemProps: {as: 'div'; className: string} = {
    as: 'div',
    className: 'hover:bg-gray-100 cursor-pointer p-2',
  };

  if (token) {
    return (
      <div className="h-8 w-8 md:h-12 md:w-12">
        <Menu as="div" className="relative">
          <Menu.Button as="div" className="cursor-pointer">
            <Bars3Icon className="p-1 h-full w-full rounded ui-open:bg-white ui-open:text-theme-navy" />
          </Menu.Button>
          <Menu.Items className="bg-white py-2 flex flex-col rounded text-theme-navy text-base text-left absolute right-0 w-40 mt-2 shadow ring-1 ring-theme-navy/20">
            <Link to="/user">
              <Menu.Item {...menuItemProps}>User Account</Menu.Item>
            </Link>
            <Link to="/content">
              <Menu.Item {...menuItemProps}>Content Library</Menu.Item>
            </Link>

            <div
              onClick={() => {
                clearToken();
                window.location.reload();
              }}
            >
              <Menu.Item {...menuItemProps}>Log Out</Menu.Item>
            </div>
          </Menu.Items>
        </Menu>
      </div>
    );
  } else {
    return <></>;
  }
}

const router = createBrowserRouter([
  {
    element: <Layout />,
    children: [
      {
        path: '/',
        element: <App />,
      },
      {
        path: '/login',
        element: <LoginRedirect />,
      },
      {
        path: '/user',
        element: (
          <RequireAuth>
            <Profile />
          </RequireAuth>
        ),
      },
      {
        path: '/content',
        element: (
          <RequireAuth>
            <ContentLibrary />
          </RequireAuth>
        ),
      },
      {
        path: '/admin',
        element: (
          <RequireAuth>
            <AdminPage />
          </RequireAuth>
        ),
      },
    ],
  },
]);

function RequireAuth({children}: {children: JSX.Element}) {
  let token = getDecodedToken();
  let location = useLocation();

  if (!token) {
    return <Navigate to="/" state={{from: location}} replace />;
  }
  return children;
}

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
