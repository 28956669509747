import React, {useState} from 'react';
import {QuestionMarkCircleIcon, XCircleIcon} from '@heroicons/react/24/outline';
import {getDecodedToken} from '../auth/token';

export default function Feedback() {
  const email = encodeURIComponent(getDecodedToken()?.sub || '');
  const [formOpen, setFormOpen] = useState(false);
  const embeddedForm = (
    <iframe
      title="feedback-form"
      src={`https://docs.google.com/forms/d/e/1FAIpQLSfG4FRGJZ9iBJTGvxSHeLZK1kHcp_hGVhKD6Offl9SXNNw_TQ/viewform?embedded=true&entry.1879859392=${email}`}
      width={640}
      height={573}
      frameBorder={0}
      marginHeight={0}
      marginWidth={0}
    >
      Loading…
    </iframe>
  );
  return (
    <>
      <QuestionMarkCircleIcon
        onClick={() => setFormOpen(true)}
        className="h-8 w-8 md:h-12 md:w-12 hover:cursor-pointer"
      />
      {formOpen && (
        <div
          className="grid place-content-center z-10 absolute top-0 left-0 h-full w-full bg-gray-500"
          style={{backgroundColor: 'rgba(0,0,0,0.4)'}}
          onClick={() => setFormOpen(false)}
        >
          <div className="rounded bg-white grid justify-items-end">
            <XCircleIcon
              onClick={() => setFormOpen(false)}
              className="m-1 h-8 w-8 text-theme-navy hover:cursor-pointer"
            />
            {embeddedForm}
          </div>
        </div>
      )}
    </>
  );
}
