import React from 'react';

const Input = React.forwardRef(
  (
    props: React.InputHTMLAttributes<HTMLInputElement>,
    ref: React.LegacyRef<HTMLInputElement>
  ) => (
    <input
      ref={ref}
      {...props}
      className={`border rounded-md border-theme-navy px-1 w-full disabled:text-gray-400 disabled:bg-gray-100 ${props.className}`}
    >
      {props.children}
    </input>
  )
);

export default Input;
