import React, {useEffect, useState} from 'react';
import MDEditor from '@uiw/react-md-editor';
import {
  addContent,
  Content,
  getAllContent,
  updateContent,
} from '../api/content';
import {Survey, getSurveys, createSurvey, deleteSurvey} from '../api/survey';
import Button from '../baseComponents/Button';
import Input from '../baseComponents/Input';
import RatingFetcher from './RatingFetcher';
import CompletionFetcher from './CompletionFetcher';

export default function AdminPage() {
  const [showAdd, setShowAdd] = useState(false);
  const [allContent, setAllContent] = useState<Content[]>([]);
  const [showAddSurvey, setShowAddSurvey] = useState(false);
  const [allSurveys, setAllSurveys] = useState<Survey[]>([]);

  useEffect(() => {
    getAllContent().then(content =>
      setAllContent(
        content.sort((a, b) =>
          a.cardinality !== b.cardinality
            ? a.cardinality - b.cardinality
            : a.id - b.id
        )
      )
    );
    getSurveys().then(setAllSurveys);
  }, []);

  return (
    <>
      <div className="text-xl">Content</div>
      <div>
        {allContent.map(content => (
          <ContentEditor key={content.id} content={content} />
        ))}
        {showAdd ? (
          <AddContent
            onContentAdded={content => setAllContent([...allContent, content])}
          />
        ) : (
          <Button onClick={() => setShowAdd(true)}>Add Content</Button>
        )}
      </div>
      <div className="text-xl">Surveys</div>
      <div>
        {allSurveys.map((survey, index) => (
          <SurveyView
            key={survey.id}
            survey={survey}
            onDeleted={() => {
              const newSurveys = [...allSurveys];
              newSurveys.splice(index, 1);
              setAllSurveys(newSurveys);
            }}
          />
        ))}
      </div>
      {showAddSurvey ? (
        <AddSurvey
          onSurveyAdded={survey => setAllSurveys([...allSurveys, survey])}
        />
      ) : (
        <Button onClick={() => setShowAddSurvey(true)}>Add Survey</Button>
      )}
      <RatingFetcher />
      <CompletionFetcher />
    </>
  );
}

function ContentEditorEditMode(props: {
  content: Content;
  onUpdate?: (content: Content) => void;
}) {
  const {content} = props;
  const [value, setValue] = useState<string | undefined>(content.text);
  const [title, setTitle] = useState<string>(content.title);
  const [week, setWeek] = useState<number>(content.cardinality + 1);
  const [videoUrls, setVideoUrls] = useState<string[]>(content.videoUrls || []);
  const [successMessage, setSuccessMessage] = useState<string>();
  const [errorMessage, setErrorMessage] = useState<string>();

  return (
    <div>
      <label htmlFor="title">Title: </label>
      <Input
        type="text"
        name="title"
        value={title}
        onChange={e => setTitle(e.target.value)}
        className="!w-auto my-1"
      ></Input>
      <br />
      <label htmlFor="week">Week: </label>
      <Input
        type="number"
        name="week"
        value={week}
        onChange={e => setWeek(+e.target.value)}
        className="!w-auto my-1"
      ></Input>
      <br />
      <MDEditor value={value} onChange={setValue} />
      <fieldset>
        <legend>Video Links</legend>
        {videoUrls
          ?.filter(videoUrl => videoUrl.trim())
          .map((url, index) => (
            <>
              <Input
                type="text"
                key={index}
                value={url}
                onChange={e => {
                  const videoUrlsCopy = [...videoUrls];
                  videoUrlsCopy.splice(index, 1, e.target.value);
                  setVideoUrls(videoUrlsCopy.filter(url => url));
                }}
                className="!w-auto my-1"
              />
              <br />
            </>
          ))}
        <Input
          type="text"
          key={videoUrls?.length || 0}
          onChange={e => {
            setVideoUrls([...(videoUrls || []), e.target.value]);
          }}
          value=""
          className="!w-auto my-1"
        />
      </fieldset>
      <Button
        onClick={async () => {
          if (!(week && title && (videoUrls?.length || value))) {
            setErrorMessage(
              'Please fill out week, title, and at least the text or video URLs.'
            );
            setSuccessMessage('');
          } else {
            setSuccessMessage('submitting...');
            setErrorMessage('');
            try {
              const updatedContent = await updateContent(content.id, {
                cardinality: week - 1,
                title,
                text: value,
                videoUrls,
              } as Content);
              setSuccessMessage('Updated!');
              if (props.onUpdate) props.onUpdate(updatedContent);
            } catch (error) {
              setErrorMessage('something went wrong, try again.');
              setSuccessMessage('');
            }
          }
        }}
        disabled={!!successMessage}
      >
        Save Content
      </Button>
      {successMessage}
      {errorMessage}
    </div>
  );
}

function ContentEditor(props: {content: Content}) {
  const [showEdit, setShowEdit] = useState(false);
  const [content, setContent] = useState(props.content);

  return showEdit ? (
    <ContentEditorEditMode
      content={content}
      onUpdate={content => {
        setContent(content);
        setShowEdit(false);
      }}
    />
  ) : (
    <div>
      <span>
        Week {props.content.cardinality + 1}: {props.content.title}
      </span>
      <Button onClick={() => setShowEdit(true)}>Edit</Button>
    </div>
  );
}

function AddContent(props: {onContentAdded?: (content: Content) => void}) {
  const [value, setValue] = useState<string>();
  const [title, setTitle] = useState<string>();
  const [week, setWeek] = useState<number>();
  const [videoUrls, setVideoUrls] = useState<string[]>();
  const [successMessage, setSuccessMessage] = useState<string>();
  const [errorMessage, setErrorMessage] = useState<string>();
  return (
    <>
      <title>Add Content</title>
      <br />
      <label htmlFor="title">Title: </label>
      <Input
        type="text"
        name="title"
        value={title}
        onChange={e => setTitle(e.target.value)}
        className="!w-auto my-1"
      ></Input>
      <br />
      <label htmlFor="week">Week: </label>
      <Input
        type="number"
        name="week"
        value={week}
        onChange={e => setWeek(+e.target.value)}
        className="!w-auto my-1"
      ></Input>
      <br />
      <MDEditor value={value} onChange={setValue} />
      <fieldset>
        <legend>Video Links</legend>
        {videoUrls
          ?.filter(videoUrl => videoUrl.trim())
          .map((url, index) => (
            <>
              <Input
                type="text"
                key={index}
                value={url}
                onChange={e => {
                  const videoUrlsCopy = [...videoUrls];
                  videoUrlsCopy.splice(index, 1, e.target.value);
                  setVideoUrls(videoUrlsCopy.filter(url => url));
                }}
                className="!w-auto my-1"
              />
              <br />
            </>
          ))}
        <Input
          type="text"
          key={videoUrls?.length || 0}
          onChange={e => {
            setVideoUrls([...(videoUrls || []), e.target.value]);
          }}
          value=""
          className="!w-auto my-1"
        />
      </fieldset>
      <Button
        onClick={async () => {
          if (!(week && title && (videoUrls?.length || value))) {
            setErrorMessage(
              'Please fill out week, title, and at least the text or video URLs.'
            );
            setSuccessMessage('');
          } else {
            setSuccessMessage('submitting...');
            setErrorMessage('');
            try {
              const newContent = await addContent({
                cardinality: week - 1,
                title,
                text: value,
                videoUrls,
              } as Content);
              setSuccessMessage('Added! Refresh if you want to add another.');
              if (props.onContentAdded) props.onContentAdded(newContent);
            } catch (error) {
              setErrorMessage('something went wrong, try again.');
              setSuccessMessage('');
            }
          }
        }}
        disabled={!!successMessage}
      >
        Add Content
      </Button>
      {successMessage}
      {errorMessage}
    </>
  );
}

function AddSurvey(props: {onSurveyAdded: (survey: Survey) => void}) {
  const [url, setUrl] = useState('');
  const [weeks, setWeeks] = useState('');
  const [successMessage, setSuccessMessage] = useState<string>();
  const [errorMessage, setErrorMessage] = useState<string>();
  return (
    <>
      <title>Add Content</title>
      <br />
      <label htmlFor="url">Url: </label>
      <Input
        type="text"
        name="url"
        value={url}
        onChange={e => setUrl(e.target.value.trim())}
        className="!w-auto my-1"
      ></Input>
      <br />
      <label htmlFor="weeks">
        Show at the end of weeks: (separate each week by comma)
      </label>
      <Input
        type="text"
        name="week"
        value={weeks}
        // allow numbers, spaces, and commas.
        onChange={e => setWeeks(e.target.value.replace(/[^\d,\s]/, ''))}
        className="!w-auto my-1"
      ></Input>
      <Button
        onClick={async () => {
          if (!(weeks && url)) {
            setErrorMessage('Please fill out the URL and weeks.');
            setSuccessMessage('');
          } else {
            setSuccessMessage('submitting...');
            setErrorMessage('');
            try {
              const newSurvey = await createSurvey({
                cardinalities: weeks
                  .replace(' ', '')
                  .split(',')
                  .map(week => +week)
                  .sort((a, b) => a - b),
                url,
              } as Survey);
              setSuccessMessage('Added! Refresh if you want to add another.');
              if (props.onSurveyAdded) props.onSurveyAdded(newSurvey);
            } catch (error) {
              setErrorMessage('something went wrong, try again.');
              setSuccessMessage('');
            }
          }
        }}
        disabled={!!successMessage}
      >
        Add Survey
      </Button>
      {successMessage}
      {errorMessage}
    </>
  );
}

function SurveyView(props: {survey: Survey; onDeleted: () => void}) {
  const survey = props.survey;
  const [isDeleting, setIsDeleting] = useState(false);
  const [errorText, setErrorText] = useState('');
  return (
    <div key={survey.id} className="py-2 flex flex-row space-x-2">
      <div>
        <div>URL: {survey.url}</div>
        <div>
          Show after completion of weeks:{' '}
          {survey.cardinalities
            .map(cardinality => +cardinality)
            .sort((a, b) => a - b)
            .join(', ')}
        </div>
      </div>
      <div>
        <Button
          disabled={isDeleting}
          onClick={async () => {
            setIsDeleting(true);
            setErrorText('');
            try {
              await deleteSurvey(survey);
              props.onDeleted();
            } catch (e) {
              setErrorText((e as Error).message);
            }
            setIsDeleting(false);
          }}
        >
          Delete
        </Button>
        {errorText}
      </div>
    </div>
  );
}
