import {getDecodedToken, getToken} from '../auth/token';

export type UserProfile = {
  id: number;
  email: string;
  password: string;
  displayName?: string;
  pregnancies?: {dueDate: Date; completionDate?: Date}[];
};

export async function getProfile() {
  const decodedToken = getDecodedToken();
  const token = getToken();
  if (!decodedToken) {
    throw new Error('not authorized');
  }
  const response = await fetch(
    `${process.env.REACT_APP_API_BASE_URL}/user?email=${encodeURIComponent(
      decodedToken.sub
    )}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
  );
  if (!response.ok) {
    console.error((await response.text()) || (await response.json()));
    throw new Error('Something went wrong. Please try again later.');
  } else {
    return response.json() as Promise<UserProfile>;
  }
}

export async function saveProfile(profile: {
  email: string;
  password: string;
  id: number;
}) {
  const decodedToken = getDecodedToken();
  const token = getToken();
  if (!decodedToken) {
    throw new Error('not authorized');
  }
  const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/user`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(profile),
  });
  if (!response.ok) {
    console.error((await response.text()) || (await response.json()));
    throw new Error('Something went wrong. Please try again later.');
  } else {
    return;
  }
}

export async function createAccount(email: string, password: string) {
  const response = await fetch(
    `${process.env.REACT_APP_API_BASE_URL}/auth/account`,
    {
      method: 'POST',
      body: JSON.stringify({email, password}),
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
  if (!response.ok) {
    throw new Error((await response.text()) || (await response.json()));
  } else {
    return response.json() as Promise<{
      email: string;
    }>;
  }
}
